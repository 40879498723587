<template>
  <div>
      <div class="title">
          <h3>公司简介</h3>
          <p>Company Profile</p>
      </div>
      <div class="jianjie-box">
          <div class="jianjie-img-box">
              <!-- <img src="../assets/images/jianjie.webp"> -->
              <iframe 
                width="100%" 
                height="100%"
                frameborder="0"
                allowfullscreen="true"
                src="https://share.plvideo.cn/front/video/preview?vid=2ff55f8622892184cabc3cacf6aba023_2">
              </iframe>
          </div>
          <div class="jianjie-text-box">
              <div class="text">
                  成都易方汇智科技有限公司，是一家专注于电子和光电子器件、建筑起重机械安全监测及相关平台整体解决方案的软硬件研发、生产和销售的高科技企业。公司荣获国家高新技术企业头衔,拥有产品核心技术自主知识产权，拥有优秀的人才团队，能为客户提供品质优良、技术先进的产品以及最优质的服务，并依托自身强大的研发力量，与西南交通大学电气工程学院形成产、学、研、用相结合的创新体系，是NEEC国家轨道交通电气化与自动化工程技术研究中心产业化基地。成都易方汇智科技有限公司成立以来，致力于为建筑行业提供安全，智能，高效的智慧建筑技术和产品解决方案，我们始终坚持服务升级，始终坚持技术革命，做起重机械安全智能监管的领航者！
              </div>
          </div>
      </div>
      <div class="small-title">
          <h3>公司实力</h3>
          <p>Company Strength</p>
      </div>
      <ul class="back-bot">
          <div class="detail-box" v-if="detailShow">
              <div class="close-out" @click="close"></div>
              <div class="close"></div>
              <div class="left-arrow" @click="prev"></div>
              <div class="img-box">
                  <img v-for="(item,i) in imgList" :key="i" v-lazy="item.src" alt="">
                  <div v-if="imgList.length < 1" class="blank">敬请期待！</div>
              </div>
              <div class="right-arrow" @click="next"></div>
          </div>
          <li>
              <div class="my-anmition">
                  <div class="detail" @click="detail(1)">查看详情</div>
              </div>
              <div class="top">
                  <i class="iconfont">&#xe637;</i>
                  <div>专业专注</div>
              </div>
              <div class="text">多年专注于起重机械安全监控主机及配套软件的研发，掌握多项技术专利。</div>
          </li>
          <li >
              <div class="my-anmition">
                  <div class="detail" @click="detail(2)">查看详情</div>
              </div>
              <div class="top">
                  <i class="iconfont">&#xe63a;</i>
                  <div>体系完善</div>
              </div>
              <div class="text">公司集硬件研发、设备生产、软件研发、售后服务、行业难点攻关研究于一体，拥有厂家到终端的完善销售体系</div>
          </li>
          <li>
              <div class="my-anmition">
                  <div class="detail" @click="detail(3)">查看详情</div>
              </div>
              <div class="top">
                  <i class="iconfont">&#xe639;</i>
                  <div>合作共赢</div>
              </div>
              <div class="text">与西南交通大学紧密合作，成为产学研单位</div>
          </li>
          <li>
              <div class="my-anmition">
                  <div class="detail" @click="detail(4)">查看详情</div>
              </div>
              <div class="top">
                  <i class="iconfont">&#xe638;</i>
                  <div>个性化定制</div>
              </div>
              <div class="text">拥有专业设计、程序开发团队，可满足客户独家定制需求，从功能、外观、使用方法方面满足客户量身定制需求。</div>
          </li>
          <li>
              <div class="my-anmition">
                  <div class="detail" @click="detail(5)">查看详情</div>
              </div>
              <div class="top">
                  <i class="iconfont">&#xe64d;</i>
                  <div>极致售后</div>
              </div>
              <div class="text">所有产品均按照国家相关政策提供售后服务，从销售、安装、使用、售后各个环节提供24小时在线专业服务。</div>
          </li>
          <li>
              <div class="my-anmition">
                  <div class="detail" @click="detail(6)">查看详情</div>
              </div>
              <div class="top">
                  <i class="iconfont">&#xe63b;</i>
                  <div>资质荣誉</div>
              </div>
              <div class="text">拥有专利5项、软件著作权18项，多家机构的质量检验认定,荣获国家高新技术企业头衔</div>
          </li>
      </ul>
      <div class="title">
          <h3>产品优势</h3>
          <p>Product Advantages</p>
      </div>
      <div class="youshi-box">
        <ul class="youshi-ul">
            <li>
                <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe63d;</i></div></div>
                <div class="title1">人脸识别驾驶</div>
                <div class="title2">人机合一，精准识别</div>
            </li>
            <li>
                <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe640;</i></div></div>
                <div class="title1">语音双向通话</div>
                <div class="title2">项目人员，实时提醒</div>
            </li>
            <li>
                <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe63c;</i></div></div>
                <div class="title1">产品集成化</div>
                <div class="title2">多位一体，集成整合</div>
            </li>
            <li>
                <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe641;</i></div></div>
                <div class="title1">塔吊防碰撞</div>
                <div class="title2">塔群作业，实时预警</div>
            </li>
            <li>
                <div class="icon-box"><div class="icon-box1"><i class="iconfont">&#xe63e;</i></div></div>
                <div class="title1">吊钩可视化</div>
                <div class="title2">吊装可视，实时记录</div>
            </li>
            <li>
                <div class="icon-box"><div class="icon-box1"><i class="iconfont" style="font-size:30px">&#xe63f;</i></div></div>
                <div class="title1">视频回放</div>
                <div class="title2">实时监控，定期回放</div>
            </li>
        </ul>
      </div>
       <div class="title">
          <h3>三大平台</h3>
          <p>Three Platforms</p>
      </div>
      <div class="pingtai-box">
          <div class="item">
              <div class="top">
                  <p class="p1">行政平台</p>
                  <p class="p2">专注于安全信息搜集，安全态势掌控</p>
              </div>
              <div class="bottom">
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe642;</i></div>
                      <p>数据大屏展示</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe63d;</i></div>
                      <p>人脸识别驾驶</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe64b;</i></div>
                      <p>多方位告警提示</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe649;</i></div>
                      <p>安全大数据分析</p>
                  </div>
              </div>
          </div>
          <div class="item">
              <div class="top">
                  <p class="p1">建机平台</p>
                  <p class="p2">销售回款统计，掌握设备实况</p>
              </div>
              <div class="bottom">
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe643;</i></div>
                      <p>销售数据统计</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe646;</i></div>
                      <p>出厂设备记录</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe64c;</i></div>
                      <p>设备远程控制</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe644;</i></div>
                      <p>回款记录统计</p>
                  </div>
              </div>
          </div>
          <div class="item">
              <div class="top">
                  <p class="p1">租赁平台</p>
                  <p class="p2">记录所有设备信息，维修巡检留痕</p>
              </div>
              <div class="bottom">
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe647;</i></div>
                      <p>租期实时跟进</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe648;</i></div>
                      <p>设备详情记录</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont">&#xe645;</i></div>
                      <p>维修记录留痕</p>
                  </div>
                  <div class="four">
                      <div class="icon-box"><i class="iconfont" style="font-size:28px">&#xe64a;</i></div>
                      <p>巡检过程监控</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="title">
          <h3>合作伙伴</h3>
          <p>Cooperative Partner</p>
      </div>
      <ul class="youshi-ul" style="height: 280px;width: 1200px;">
            <li>
                <img v-lazy="require('../assets/images/中建集团.webp')">
            </li>
            <li>
                <img v-lazy="require('../assets/images/中国交建.webp')">
            </li>
            <li>
                <img v-lazy="require('../assets/images/广西建工.webp')">
            </li>
            <li>
                <img v-lazy="require('../assets/images/锦城建机.webp')">
            </li>
            <li>
                <img v-lazy="require('../assets/images/华西集团.webp')">
            <li>
                <img v-lazy="require('../assets/images/北京城建集团.webp')">
            </li>
            <li>
                <img v-lazy="require('../assets/images/中交二公局.webp')">
            </li>
            <li>
                <img v-lazy="require('../assets/images/宏升重工.webp')">
            </li>
            <li>
                <img v-lazy="require('../assets/images/四川路桥.webp')">
            </li>
            <li>
                <img v-lazy="require('../assets/images/庞源.webp')">
            </li>
        </ul>
  </div>
</template>

<script>
export default {
    data(){
        return {
            detailShow: false,
            imgList:[],
            itemIndex:1,
            pageIndex:1,
        }
    },
    created(){
        document.body.style.height = "100%"
        document.documentElement.style.height = "100%"
    },
    mounted(){
        
    },
    methods:{
        //点击查看详情
        detail(index){
            this.imgList = [];
            this.itemIndex = index;
            this.pageIndex = 1;
            this.detailShow = true;
            if(index == 1){
                for(let i = 1; i <= 3; i++){
                    this.imgList.push({
                        id: i,
                        src: require(`../assets/images/shili/zy${i}.webp`)
                    })
                }
            }
            if(index == 2){
                this.imgList.push({
                    id: 1,
                    src: require(`../assets/images/tixi/tx${1}.webp`)
                })
            }
            if(index == 6){
                this.imgList.push({
                    id: 1,
                    src: require(`../assets/images/zizhi/zz${1}.webp`)
                })
            }
            if(index == 5){
                this.imgList.push({
                    id: 1,
                    src: require(`../assets/images/shouhou/sh${1}.webp`)
                })
            }
        },
        //关闭查看详情
        close(){
            this.detailShow = false
        },
        //上一页
        prev(){
            if(this.pageIndex - 1 <= 0) return;
            if(this.itemIndex == 1){
                this.pageIndex --;
                this.changeSrc();
            }
            if(this.itemIndex == 2){
                this.pageIndex --;
                this.imgList = [];
                this.imgList.push({
                    id: 1,
                    src: require(`../assets/images/tixi/tx${1}.webp`)
                })
            }
            if(this.itemIndex == 6){
                this.pageIndex --;
                this.imgList = [];
                this.imgList.push({
                    id: this.pageIndex,
                    src: require(`../assets/images/zizhi/zz${this.pageIndex}.webp`)
                })
            }
            if(this.itemIndex == 5){
                return
            }
        },
        //下一页
        next(){
            if(this.itemIndex == 1){
                if(this.pageIndex + 1 >= 4) return;
                this.pageIndex ++;
                this.changeSrc();
            }
            if(this.itemIndex == 2){
                if(this.pageIndex + 1 >= 3) return;
                this.pageIndex ++;
                this.imgList = [];
                this.imgList.push({
                    id: 2,
                    src: require(`../assets/images/tixi/tx${2}.webp`)
                })
            }
            if(this.itemIndex == 6){
                if(this.pageIndex + 1 >= 14) return;
                this.pageIndex ++;
                this.imgList = [];
                this.imgList.push({
                    id: this.pageIndex,
                    src: require(`../assets/images/zizhi/zz${this.pageIndex}.webp`)
                })
            }
            if(this.itemIndex == 5){
                return
            }
        },
        //替换src的函数 this.itemIndex == 1
        changeSrc(){
            switch (this.pageIndex){
                case 1 :
                    this.imgList = [];
                    for(let i = 1; i <= 3; i++){
                        this.imgList.push({
                            id: i,
                            src: require(`../assets/images/shili/zy${i}.webp`)
                        })
                    }
                break;
                case 2 :
                    this.imgList = [];
                    for(let i = 4; i <= 6; i++){
                        this.imgList.push({
                            id: i,
                            src: require(`../assets/images/shili/zy${i}.webp`)
                        })
                    }
                break;
                case 3 :
                    this.imgList = [];
                    for(let i = 7; i <= 9; i++){
                        this.imgList.push({
                            id: i,
                            src: require(`../assets/images/shili/zy${i}.webp`)
                        })
                    }
                break;
                case 4 :
                    this.imgList = [];
                    for(let i = 10; i <= 12; i++){
                        this.imgList.push({
                            id: i,
                            src: require(`../assets/images/shili/zy${i}.webp`)
                        })
                    }
                break;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.title{
    color: #4D4D4D;
    letter-spacing: 3px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3{
        font-size: 21px;
    }
    p{
        color: #808080;
        font-size: 15px;
        letter-spacing: 0;
    }
}
.jianjie-box{
    height: 400px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    
    .jianjie-img-box{
        width: 600px;
        height: 400px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .jianjie-text-box{
        width: 550px;
        .title{
            color: #4D4D4D;
            letter-spacing: 3px;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            h3{
                font-size: 17px;
            }
            p{
                color: #808080;
                font-size: 14px;
                letter-spacing: 0;
            }
        }
        .text{
            color: #4D4D4D;
            line-height: 35px;
            letter-spacing: 1px;
            margin-top: 5%;
            text-indent: 2rem;
        }
    }
}
.small-title{
    height: 100px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 3px;
    h3{
        font-size: 17px;
    }
    p{
        color: #808080;
        font-size: 14px;
        letter-spacing: 0;
    }
}
.back-bot{
    height: 600px;
    width: 1200px;
    margin: -25px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 40px 55px;
    box-sizing: border-box;
    position: relative;
    .detail-box{
        width: 100%;
        height: 100%;
        background: rgba($color: #23272A, $alpha: 0.5);
        position: absolute;
        left: 0;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .close-out{
            position: absolute;
            z-index: 30;
            width: 32px;
            height: 32px;
            top: 10px;
            right: 13px;
            cursor: pointer;
        }
        .close{
            position: absolute;
            z-index: 20;
            width: 32px;
            height: 2px;
            top: 28px;
            right: 13px;
            background: #fff;
            transform: rotate(45deg); 
            
        }
        .close:after{
            content: '';
            width: 32px;
            height: 2px;
            display: block;
            background: #fff;
            transform: rotate(90deg);
        }
        .img-box{
            width: 1095px;
            height: 500px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .blank{
                color: #fff;
                letter-spacing: 2px;
                font-weight: bold;
                font-size: 1.2rem;
            }
        }
        .left-arrow,.right-arrow{
            width: 60px;
            height: 50px;
            cursor: pointer;
        }
        .left-arrow:before,.right-arrow:before{
            content: '';
            width: 25px;
            height: 2px;
            display: block;
            background: #fff;
            transform: rotate(45deg) translate(22px, -2px); 
        }
        .left-arrow:after,.right-arrow:after{
            content: '';
            width: 25px;
            height: 2px;
            display: inline-block;
            background: #fff;
            transform: rotate(135deg) translate(0px, -24px);
            
        }
        .left-arrow:before{
            transform: rotate(-45deg) translate(1px, 20px); 
        }
        .left-arrow:after{
            transform: rotate(45deg) translate(22px, 2px); 
        }
        .left-arrow:active:before,.left-arrow:active:after,.right-arrow:active:before,.right-arrow:active:after{
            background: rgba($color: #fff, $alpha: 0.7);
        }
        // .right-arrow{

        // }
    }
    li{
        background: #E7EAF1;
        box-shadow: 7px 5px 16px 0px #C5C5C5, -8px -8px 27px 5px #FFFFFF, 0px 3px 8px 0px #F4F7FF, 2px 2px 8px 0px #DBDEE4;
        width: 320px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
        .top{
            width: 100%;
            height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            i{
                font-size: 30px;
                color: #4D97FF;
            }
            div{
                color: #4D4D4D;
                font-weight: bold;
                font-size: 17px;
                margin-top: 5px;
            }
        }
        .text{
            width: 100%;
            height: 110px;
            padding: 0 20px;
            margin-top: 10px;
            box-sizing: border-box;
            color: #666666;
            font-size: 15px;
            letter-spacing: 1px;
            line-height: 25px;
            text-align: center;
        }
        .my-anmition{
            top: -200px;
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($color: #000000, $alpha: 0.5);
            transition: all 0.4s;
            .detail{
                width: 150px;
                height: 40px;
                border-radius: 20px;
                text-align: center;
                line-height: 40px;
                background: #fff;
                color: #4D4D4D;
                font-size: 15px;
                letter-spacing: 2px;
                cursor: pointer;
            }
            .detail:active{
                background: rgba($color: #fff, $alpha: 0.8);
            }
        }
       
    }
    li:hover{
        .my-anmition{
            transform: translateY(200px);
        }
    }
    .center-li{
        background: #4D97FF;
        .top{
            i{
                color: #fff;
            }
            div{
                color: #fff;
            }
        }
        .text{
            color: #fff !important;
        }
    }
    li:hover{
        background: #4D97FF;
        .top{
            i{
                color: #fff;
            }
            div{
                color: #fff;
            }
        }
        .text{
            color: #fff !important;
        }
        transition: all 0.3s;
        box-shadow: 7px 5px 16px 7px #C5C5C5, -8px -8px 27px 7px #FFFFFF, 0px 3px 8px 7px #F4F7FF, 2px 2px 8px 7px #DBDEE4;
    }
}
.youshi-box{
    width: 100%;
    height: 350px;
    background-size: 100% 100%;
}
.youshi-ul{
    width: 844px;
    height: 310px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
        width: 230px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon-box{
            width: 84px;
            height: 84px;
            background: #E8EBF2;
            box-shadow: 7px 5px 16px 0px #C5C5C5, -8px -8px 27px 5px #FFFFFF, 0px 3px 8px 0px #F4F7FF, 2px 2px 8px 0px #DBDEE4;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-box1{
                background: #4D97FF;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 1px;
                 i{
                    font-size: 35px;
                    color: #fff;
                }
            }
        }
        .title1{
            color: #4D4D4D;
            font-weight: 600;
            margin: 5px 0;
            font-size: 15px;
        }
        .title2{
            color: #808080;
            font-size: 13px;
        }
        img{
            width: 100px;
            height: 100px;
            box-shadow: 7px 5px 16px 0px #C5C5C5, -8px -8px 27px 5px #FFFFFF, 0px 3px 8px 0px #F4F7FF, 2px 2px 8px 0px #DBDEE4;
        }
    }
}
.pingtai-box{
    width: 1200px;
    height: 460px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .item{
        width: 360px;
        background: #E6E9EF;
        box-shadow: -3px -2px 5px 1px #D0D6E2, 3px 2px 5px 1px #fff;
        .top{
            height: 100px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .p1{
                font-weight: bold;
                font-size: 17px;
                color: #4D4D4D;
            }
            .p2{
                font-size: 15px;
                margin-top: 5px;
                color: #666666;
            }
        }
        .bottom{
            height: 360px;
            display: flex;
            flex-wrap: wrap;
            .four{
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .icon-box{
                    border-left: 2px solid #eee;
                    border-top: 2px solid #eee;
                    width: 95px;
                    height: 95px;
                    background: #E7EAF1;
                    box-shadow: 7px 5px 16px 0px #C5C5C5, -3px -3px 27px 5px #FFFFFF, 0px 3px 8px 0px #F4F7FF, 2px 2px 8px 0px #DBDEE4;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                        font-size: 37px;
                        color: #4D97FF;
                    }
                }
                p{
                    margin-top: 7px;
                    color: #4D4D4D;
                    font-size: 15px;
                }
            }
        }
    }
}
</style>